/*eslint-disable import/default*/
import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom'
import './styles/styles.scss';
require('./favicon.ico');

render (
  <BrowserRouter>
    <App />
  </BrowserRouter>
, document.getElementById('app')
);
