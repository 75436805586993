import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const Header = (props) => {
    return (
      <div className="header">
        <header className="wrapper clearfix">
          <h1 className="header__title">{props.title}</h1>
          <h2 className="header__sub-title">{props.subTitle}</h2>
            <nav className="nav header__nav">
              <div>
                <ul className="nav__list">
                  {props.links.map((link, index) => {
                    let additionalClass = '';
                    if ( index === 0 ) additionalClass = 'nav__item--border-first';
                    if ( index === props.links.length-1 ) additionalClass = 'nav__item--border-last';
                    return <li className={`nav__item ${additionalClass}`} key={link.linkUrl}><Link to={"/" + link.linkUrl} className="nav__link">{link.linkTitle}</Link></li>
                  })}
                </ul>
              </div>
            </nav>
        </header>
      </div>
    );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object)
}

export default Header;
