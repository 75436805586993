import React from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import workData from '../../data/work.json';
import ReactHtmlParser from 'react-html-parser';

class Work extends React.Component {
    constructor(props, context) {
      super(props, context);
    }
    componentDidUpdate() {
      // Typical usage (don't forget to compare props):
      //if (this.props.userID !== prevProps.userID) {
      //  this.fetchData(this.props.userID);
      //}
      AOS.refresh();
    }
    render() {
        return (
          <section id="work" className="clearfix" ref={this.props.workRef}>
            <div className="intro">
              <header className="clearfix">
                <h1 className="intro__title">{workData.title}</h1>
                <p>{workData.intro}</p>
              </header>
            </div>
            {workData.projects.map((project, index) => {
            return <article key={index} className="content clearfix" data-aos="fade-up" data-aos-duration="600" data-aos-easing="ease-in-out" data-aos-once="true" data-aos-anchor-placement="center-bottom">
              <div className="content__column content__column--col-1">
              {project.viewMoreUrl != '' ? (<a href={project.viewMoreUrl} rel="noopener noreferrer" target="_blank" title={"View " + project.title + "(opens in a new window)"}><img src={project.imageUrl} alt={project.title} className="content__circle-img" /></a>) : (<img src={project.imageUrl} alt={project.title} className="content__circle-img" />)}
              </div>
              <div className="content__column content__column--col-2">
                <header>
                  <h1 className="content__title">{project.title}</h1>
                </header>
                <section className="content__copy">
                  {project.summary.map((item2, index2) => {
                    return <p key={index2}>{ReactHtmlParser(item2)}</p>
                  })}
                  {project.viewMoreUrl != '' ?
                  (<a href={project.viewMoreUrl} className="content__external-link" rel="noopener noreferrer" target="_blank" title={"View " + project.title + "(opens in a new window)"}>View</a>)
                  : ('')}
                </section>
                <aside className="list content__list">
                  <h2 className="list__title">Made with:</h2>
                  <ul className="list__list">
                    {project.madeWith.map((item3, index3) => {
                      return <li key={index3} className="list__item">{item3}</li>
                    })}
                  </ul>
                </aside>
              </div>
            </article>
            })}
          </section>
        );
    }
}

Work.propTypes = {
  workRef: PropTypes.object
}

export default Work;
