import React from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import contactData from '../../data/contact.json';
import ReactHtmlParser from 'react-html-parser';

class Contact extends React.Component {
    constructor(props, context) {
      super(props, context);
    }
    componentDidUpdate() {
      // Typical usage (don't forget to compare props):
      //if (this.props.userID !== prevProps.userID) {
      //  this.fetchData(this.props.userID);
      //}
      AOS.refresh();
    }
    render() {
        return (
          <section id="contact" className="intro intro--contact clearfix" ref={this.props.contactRef}>
            <header className="clearfix">
              <h1 className="intro__title">{contactData.title}</h1>
              {contactData.copy.map((item, index) => {
                return <p key={index}>{ReactHtmlParser(item)}</p>
              })}
            </header>
          </section>
        );
    }
}

Contact.propTypes = {
  contactRef: PropTypes.object
}

export default Contact;
