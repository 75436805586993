import React from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import About from './About';
import Work from './Work';
import Contact from './Contact';

class HomePage extends React.Component {
    constructor(props, context) {
      super(props, context);
      AOS.init();
    }
    componentDidUpdate() {
      AOS.refresh();
    }
    render() {
        return (
          <div className="main-container">
            <div className="main wrapper clearfix">
              <About aboutRef={this.props.aboutRef} />
              <Work workRef={this.props.workRef} />
              <Contact contactRef={this.props.contactRef} />
            </div>
          </div>
        );
    }
}

HomePage.propTypes = {
  aboutRef: PropTypes.object.isRequired,
  workRef: PropTypes.object.isRequired,
  contactRef: PropTypes.object.isRequired,
}

export default HomePage;
