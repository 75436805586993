import React from 'react';
import {Link} from 'react-router-dom';

class NotFoundPage extends React.Component {
    render() {
        return (
          <div className="main-container">
            <div className="main wrapper clearfix">
                <h1>Whoops! Page not found</h1>
                <Link to="/">Try the home page</Link>
            </div>
          </div>
        );
    }
}

export default NotFoundPage;
