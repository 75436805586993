/* eslint-disable no-console */
import React from 'react';
import Header from './common/Header';
import { Switch, Route } from 'react-router-dom';
import HomePage from "./home/HomePage";
import NotFoundPage from "./notfound/NotFoundPage";

class App extends React.Component {
    constructor(props) {
      super(props);
      this.onNavClick.bind(this);

      // Setup references to elements for scrolling
      this.aboutRef = React.createRef();
      this.workRef = React.createRef();
      this.contactRef = React.createRef();

      // State for the navigation and header
      this.state = {
        title: 'Getting Better',
        subTitle: 'Work by web developer Jamie Whittle',
        links: [
          {
            linkTitle: 'About me',
            linkUrl: '#about',
            linkClass: 'about'
          },
          {
            linkTitle: 'Work',
            linkUrl: '#work',
            linkClass: 'work'
          },
          {
            linkTitle: 'Contact',
            linkUrl: '#contact',
            linkClass: 'contact'
          }
        ]
      }

      // State for IE status
      this.state.isIE = this.isIE();
    }

    // Component mounted
    componentDidMount() {
      document.addEventListener('click', this.onNavClick);
    }

    componentWillunmount() {
      document.removeEventListener('click', this.onNavClick);
    }
    
    // Is IE?
    isIE() {
      return !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g) || !!navigator.userAgent.match(/Edge/g);
    }


    // Nav link clicked - scroll to element using refs
    onNavClick = (e) => {
      let targetEl = '';
      switch(e.target.hash) {
        case '#about':
          targetEl = this.aboutRef.current.offsetTop;
          break;
        case '#work':
          targetEl = this.workRef.current.offsetTop;
          break;
        case '#contact':
          targetEl = this.contactRef.current.offsetTop;
          break;
        default:
          console.log('Non internal link clicked');
          //targetEl = this.aboutRef.current.offsetTop;
      }
      if (targetEl != '') {
        e.preventDefault();
        if (this.state.isIE) {
          window.scrollTo(0, targetEl);          
        }
        else {
          window.scrollTo({
            top: targetEl,
            behavior: 'smooth'
          });
        }
      }
    }
    render() {
        return (
            <div className="container-fluid">
                <Header {...this.state} />
                <Switch>
                  <Route exact path='/' render={() => <HomePage aboutRef={this.aboutRef} workRef={this.workRef} contactRef={this.contactRef} />} />
                  <Route component={NotFoundPage} />
                </Switch>
            </div>
        );
    }
}


export default App;
