import React from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import aboutData from '../../data/about.json';
import ReactHtmlParser from 'react-html-parser';


class About extends React.Component {
    constructor(props, context) {
      super(props, context);
    }
    componentDidUpdate() {
      AOS.refresh();
    }
    render() {
        return (
          <section id="about" className="content content--about clearfix" ref={this.props.aboutRef}>
            <div className="content__column content__column--col-1">
              <img src={aboutData.imageUrl} alt={aboutData.imageAlt} className="content__circle-img" />
            </div>
            <div className="content__column content__column--col-2">
              <header>
                <h1 className="content__title">{aboutData.title}</h1>
              </header>
              <section className="content__copy">
                {aboutData.intro.map((item, index) => {
                  return <p key={index}>{ReactHtmlParser(item)}</p>
                })}
              </section>
              <aside className="content__list list">
                <h2 className="list__title">{aboutData.skillsTitle}</h2>
                <ul className="list__list">
                  {aboutData.skillsList.map((item, index) => {
                    return <li key={index} className="list__item">{item}</li>
                  })}
                </ul>
              </aside>
            </div>
          </section>
        );
    }
}

About.propTypes = {
  aboutRef: PropTypes.object.isRequired
}

export default About;
